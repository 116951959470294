// Fonts
@import url("https://fonts.googleapis.com/css2?family=Mukta+Malar:wght@200;300;400;500;600;700;800");

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fonts

@import '~izitoast/dist/css/iziToast.min.css';

@import '~owl.carousel/dist/assets/owl.carousel.min.css';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

.outerDivFull { margin:50px; }

.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 50px; max-width: 50px; height: 30px; background: #d1d1d1; display: block; border-radius: 100px; position: relative; }
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #009cfd; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: ''; position: absolute; top: 5px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: ''; position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; }
.toggle-switchArea { margin: 10px 0 10px 0; }

.sidebar-icon {
	margin-right: 5px;
}

.rounded-container {
    border-radius: 7px;
}

.ih-50 {
	height: 50px;
}

pre {
	font-size: 16px;
}

.badge {
	font-size: initial;
}

.br-top {
	border-top: 2px solid #0bbfcc;
}

.bb-teal {
	border-bottom: 1px solid #14a3b8 !important;
}

.z100 {
	z-index: 1000;
}
